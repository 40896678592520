import { Token } from "../../../store/token/api/types";
import { GradientCard } from "../../ui/GradientCard";
import CreatedBy from "../Token/TokenDetails/CreatedBy";
import TokenImage from "../Token/TokenImage";
import { NavLink } from "react-router-dom";
import { moneyFormat } from "../../../helpers/moneyFormat";

const TopTokenBanner = ({ token }: { token: Token | null }) => {
  if (!token) return;
  const { marcetCap, name, ticker, userName, logo, id } = token;
  return (
    <NavLink to={`/pumpadur/tokens/${id}`}>
      <GradientCard backgroundColor="#0d0903">
        <div className="h-min w-full rounded-2xl bg-cover bg-top bg-[url('/top-token-background.png')] flex flex-col items-center pt-[2vh] leading-none gap-[14px]">
          <TokenImage
            imageSrc={logo}
            classesName="size-full object-cover w-[56px] h-[56px] min-w-[56px] min-h-[56px] rounded-full"
          />
          <div className="flex flex-col justify-center items-center gap-[10px] pb-3">
            <div className="uppercase font-bold">
              <span>{name}</span> - <span>${ticker}</span>
            </div>
            <div>
              <span className="text-medGrey font-medium">Market Cap: </span>
              <span className="text-green font-bold">${moneyFormat(+marcetCap+4000, 0)}</span>
            </div>
            <CreatedBy userName={userName} generalStyles="pb-[10px]" />
          </div>
        </div>
      </GradientCard>
    </NavLink>
  );
};

export default TopTokenBanner;
