import { useState, useCallback } from "react";
import {
   PublicKey } from "@solana/web3.js";
import bs58 from "bs58";
import { Buffer } from "buffer";

import { useTelegramApp } from "./useTelegramApp";
import { useSelector } from "react-redux";
import {
  associatedAccountsSelector,
  currentCountTokensAndPriceSelector,
  currentCountTokensForSellSelector,
  currentTokenSelector,
} from "../store/token/tokensReducer";
import {
  cryptoProfilePublicKeySelector,
  sharedSecretSelector,
} from "../store/cryptoProfile/cryptoProfileReducer";
import { getCreateTokenTransactionLink} from "../api/tokens/getTokenCreateTransactionLink";
import { getSellTokenTransactionLink } from "../api/tokens/getSellTokenTransactionLink";
import { getBuyTokenTransactionLink } from "../api/tokens/getBuyTokenTransactionLink";

global.Buffer = global.Buffer || Buffer;

export const useTransaction = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);


  const currentCountTokensAndPrice = useSelector(currentCountTokensAndPriceSelector);
  const associatedAccounts = useSelector(associatedAccountsSelector);
  const currentCountTokensForSell = useSelector(currentCountTokensForSellSelector);
  const token = useSelector(currentTokenSelector);
  const phantomWalletPublicKeyString = useSelector(cryptoProfilePublicKeySelector);
  const phantomWalletPublicKey: PublicKey | null = phantomWalletPublicKeyString
    ? new PublicKey(phantomWalletPublicKeyString)
    : null;
  const sharedSecretString = useSelector(sharedSecretSelector);
  const sharedSecret = sharedSecretString ? bs58.decode(sharedSecretString) : null;
  const tgApp = useTelegramApp();

  // Підписання та відправка транзакції
  const buyTokenTransaction = useCallback(async () => {
    if (!token) throw new Error("missing token");
    if (!sharedSecret) throw new Error("missing sharedSecret from user");
    if (!phantomWalletPublicKey) throw new Error("missing public key from user");
    const sumForPay = currentCountTokensAndPrice?.sumForPay || 0;
    try {
      const responseURL = await getBuyTokenTransactionLink(sumForPay, token.id, token.mintAddress)
      tgApp.openLink(responseURL.toString());
      tgApp.close();
    } catch (err) {
      setError(err as Error);
      console.error("Error during sign and send transaction:", err);
    } finally {
      setIsLoading(false);
    }
  }, [currentCountTokensAndPrice, phantomWalletPublicKey]);

  const createTokenTransaction = useCallback(
    async (amount: number) => {
      if (!sharedSecret) throw new Error("missing sharedSecret from user");
      if (!phantomWalletPublicKey) throw new Error("missing public key from user");
      try {
        const responseURL = await getCreateTokenTransactionLink(amount)
        tgApp.openLink(responseURL.toString());
        tgApp.close();
      } catch (err) {
        setError(err as Error);
        console.error("Error during sign and send transaction:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [currentCountTokensAndPrice, phantomWalletPublicKey],
  );

  const sellTokenTransaction = useCallback(
    async (amountOfToken: number) => {
      if (!token) throw new Error("missing public key from user");
      if (!associatedAccounts) throw new Error("You have not bought this token yet!");
      if (!sharedSecret) throw new Error("missing sharedSecret from user");
      if (!phantomWalletPublicKey) throw new Error("missing public key from user");
      try {
        const responseURL = await getSellTokenTransactionLink(amountOfToken, token.id)
        tgApp.openLink(responseURL.toString());
        tgApp.close();
      } catch (err) {
        setError(err as Error);
        console.error("Error during sign and send transaction:", err);
      } finally {
        setIsLoading(false);
      }
    },
    [associatedAccounts, token, phantomWalletPublicKey, currentCountTokensForSell],
  );

  return {
    isLoading,
    error,
    createTokenTransaction,
    buyTokenTransaction,
    sellTokenTransaction,
  };
};
