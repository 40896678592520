import React, { useCallback, useEffect, useState } from "react";
import InputAmountField from "./InputAmountField";
import Button from "../../../ui/Button";
import { TradeType, setBackgroundTradeType } from "./FilterTrade";
import ConfirmPurchase from "./ConfirmPurchase";
import { useSelector } from "react-redux";
import {
  associatedAccountsSelector,
  currentCountTokensAndPriceSelector,
  currentCountTokensForSellSelector,
  currentTokenPriceDataSelector,
  currentTokenSelector,
  socketConnectSelector,
} from "../../../../store/token/tokensReducer";
import { useAppDispatch } from "../../../../store/hooks";
import { fetchCountTokensAndPriceAction } from "../../../../store/token/actions/fetchCountTokensAndPriceAction";
import { debounce } from "lodash-es";
import {
  cryptoProfileBalanceSelector,
  isActiveWalletSelector,
} from "../../../../store/cryptoProfile/cryptoProfileReducer";
import { getDataSavedTransaction } from "../../../../api/transactions/getDataSavedTransaction";
import { useToastNotifications } from "../../../../hooks/useToastNotifications";
import { ToastNotificationType } from "../../../../store/toastNotifications/toastNotificationsReducer";
import { fetchCountTokensForSellAction } from "../../../../store/token/actions/fetchCountTokensForSellAction";
import { GetCountTokensAndPriceBody } from "../../../../api/tokens/types";
import AmountOption from "./AmountOption";
import { useParams } from "react-router-dom";
import { fetchTokenInfoAction } from "../../../../store/token/actions/fetchTokenInfoAction";
import { fetchCryptoProfileAction } from "../../../../store/cryptoProfile/actions/fetchCryptoProfileAction";
import { tgChatIdSelector } from "../../../../store/account/account";

const DEFAULT_INPUT_VALUE = 1;

const TransactionControl = React.memo(({ activeTradeType }: { activeTradeType: TradeType }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isDisableTransaction, setIsDisableTransaction] = useState<boolean>(false);
  const { tokenId } = useParams();
  const amountOptions = [3, 50, 100, "MAX"];

  const [inputAmount, setInputAmount] = useState<number | string>(DEFAULT_INPUT_VALUE);
  const [selectedAmountOption, setSelectedAmountOption] = useState<number | string | null>(null);
  const [message, setMessage] = useState<string>("");

  const dispatch = useAppDispatch();
  const { showNotification } = useToastNotifications();

  const currentTokenPriceData = useSelector(currentTokenPriceDataSelector);
  const tgChatId = useSelector(tgChatIdSelector);
  const currentCountTokensAndPrice = useSelector(currentCountTokensAndPriceSelector);
  const isUserHaveAccount = currentCountTokensAndPrice?.isUserHaveAccount || false;
  const currentCountTokensForSell = useSelector(currentCountTokensForSellSelector);
  const token = useSelector(currentTokenSelector);
  const phantomWalletSolanaBalance = useSelector(cryptoProfileBalanceSelector);
  const associatedAccounts = useSelector(associatedAccountsSelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);
  const socket = useSelector(socketConnectSelector);
  const getActualCountToken = async (value: number | null = null) => {
    if (!token) return;
    const amount = value ? value : +inputAmount;
    const data: GetCountTokensAndPriceBody = {
      priceWithFee: currentTokenPriceData?.priceWithFee || 0,
      priceWithoutFee: currentTokenPriceData?.priceWithoutFee || 0,
      amount: amount || 0,
      mintAddress: token.mintAddress,
      myFee: currentTokenPriceData?.fee || 0,
    };

    try {
      if (activeTradeType === TradeType.BUY) {
        await dispatch(fetchCountTokensAndPriceAction(data)).unwrap();
      } else if (activeTradeType === TradeType.SELL) {
        if (associatedAccounts) {
          await dispatch(fetchCountTokensForSellAction(data)).unwrap();
        }
      }
    } catch (error) {
      console.error("Error fetching token data:", error);
    }
  };

  useEffect(() => {
    dispatch(fetchCryptoProfileAction());
  }, [activeTradeType, dispatch]);

  useEffect(() => {
    if (!socket) return;

    const handleTokenUpdated = async (update: { tokenId: number; eventId: string }) => {
      console.log("Token updated:", update);
      socket.emit("ack", { eventId: update.eventId });
      if (update.tokenId === token?.id) {
        try {
          const updatedTokenData = await dispatch(fetchTokenInfoAction(tokenId!)).unwrap();
          console.log("Updated token data:", updatedTokenData);

          const data: GetCountTokensAndPriceBody = {
            priceWithFee: updatedTokenData.tokenPriceData?.priceWithFee || 0,
            priceWithoutFee: updatedTokenData.tokenPriceData?.priceWithoutFee || 0,
            amount: Number(inputAmount) || 0,
            mintAddress: token.mintAddress,
            myFee: updatedTokenData.tokenPriceData?.fee || 0,
          };

          const tokenSendPrice = await dispatch(fetchCountTokensAndPriceAction(data)).unwrap();
          const tokenForSall = await dispatch(fetchCountTokensForSellAction(data)).unwrap();

          const countOftokens =
            activeTradeType === TradeType.BUY ? tokenSendPrice?.tokensToRelease || 0 : tokenForSall?.totalSol || 0;

          setMessage(
            activeTradeType === TradeType.BUY
              ? `You will receive ≈ ${countOftokens} $${updatedTokenData.token?.ticker}`
              : `You will receive ≈ ${countOftokens} SOL`,
          );
        } catch (error) {
          console.error("Error updating token data:", error);
        }
      }
    };

    // const handleBalanceUpdated = async (update: { tgChatId: number }) => {
    //   console.log("update.tgChatId", update.tgChatId, update.tgChatId === Number(tgChatId));
    //   if (update.tgChatId === Number(tgChatId)) {
    //     dispatch(fetchCryptoProfileAction());
    //   }
    // };

    socket.on("token-updated", handleTokenUpdated);
    // socket.on("solBalance-updated", handleBalanceUpdated);

    return () => {
      socket.off("token-updated", handleTokenUpdated);
      // socket.off("solBalance-updated", handleBalanceUpdated);
    };
  }, [socket, token, tgChatId, inputAmount, activeTradeType, dispatch]);

  const debouncedInputChange = useCallback(
    debounce((value: number) => {
      getActualCountToken(value);
    }, 300),
    [activeTradeType, selectedAmountOption, currentTokenPriceData, inputAmount, token, associatedAccounts],
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "") {
      setInputAmount("");
      return;
    }

    const isValidInput = /^[0-9]+\.?[0-9]*$/.test(value);

    const dotCount = value.split(".").length - 1;
    if (!isValidInput || dotCount > 1) {
      return;
    }
    const hasLeadingZeros = /^0[0-9]+/.test(value);
    const decimalPart = value.split(".")[1];
    if ((decimalPart && decimalPart.length > 7) || hasLeadingZeros) {
      return;
    }
    const numericValue = Number(value);

    if (numericValue < 0) {
      return;
    }

    setInputAmount(value);
    debouncedInputChange(numericValue);
  };

  const handleAmountClick = (amount: number | string) => {
    if (!isActiveWallet) return;

    let newAmount = amount;

    if (amount === "MAX") {
      newAmount =
        activeTradeType === TradeType.BUY ? phantomWalletSolanaBalance || 0 : associatedAccounts?.tokenCount || 0; // Для BUY и SELL разные значения
    }

    setInputAmount(newAmount);
    debouncedInputChange(Number(newAmount));
    setSelectedAmountOption(amount);
  };

  const buyTokenTransaction = async () => {
    if (phantomWalletSolanaBalance && +phantomWalletSolanaBalance < (+inputAmount || 0)) {
      showNotification({
        title: "Insufficient funds!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
    setIsOpened(true);
  };

  const sellTokenTransaction = async () => {
    if (!associatedAccounts || +associatedAccounts?.tokenCount! <= 0) {
      showNotification({
        title: "You have not bought this token yet!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
    if (+associatedAccounts?.tokenCount! < (+inputAmount || 0)) {
      showNotification({
        title: "Insufficient funds!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
    if (+currentCountTokensForSell?.totalSol! > +token!.liquidity) {
      showNotification({
        title: "Sorry! Not enough sol on the pool wallet!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
    setIsOpened(true);
  };

  const handleTransaction = async () => {
    try {
      const dataSavedTransaction = await getDataSavedTransaction();

      if (dataSavedTransaction) {
        showNotification({
          title: "Error! The previous transaction is not completed!",
          type: ToastNotificationType.Error,
          timeoutMs: 3000,
        });
        return;
      }
    } catch (error) {
      console.error("Error fetching saved transaction:", error);
      showNotification({
        title: "Error! Something went wrong when checking the saved transaction!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
    if (+inputAmount <= 0) {
      showNotification({
        title: "Enter the correct value!",
        type: ToastNotificationType.Error,
        timeoutMs: 3000,
      });
      return;
    }
    if (isActiveWallet) {
      if (phantomWalletSolanaBalance && +phantomWalletSolanaBalance <= 0) {
        showNotification({
          title: "Insufficient funds!",
          type: ToastNotificationType.Error,
          timeoutMs: 3000,
        });
        return;
      }
      if (activeTradeType === TradeType.BUY) buyTokenTransaction();
      if (activeTradeType === TradeType.SELL) sellTokenTransaction();
    } else {
      showNotification({
        title: "Connect your wallet!",
        type: ToastNotificationType.Error,
      });
    }
  };

  useEffect(() => {
    const countOftokens =
      activeTradeType === TradeType.BUY
        ? currentCountTokensAndPrice?.tokensToRelease || 0
        : currentCountTokensForSell?.totalSol || 0;

    setMessage(
      activeTradeType === TradeType.BUY
        ? `You will receive ≈ ${countOftokens} $${token?.ticker}`
        : `You will receive ≈ ${countOftokens} SOL`,
    );
  }, [currentTokenPriceData, activeTradeType, currentCountTokensAndPrice, currentCountTokensForSell, token?.ticker]);

  useEffect(() => {
    setInputAmount(DEFAULT_INPUT_VALUE);
    debouncedInputChange(DEFAULT_INPUT_VALUE);
    setSelectedAmountOption(null);
  }, [activeTradeType]);

  useEffect(() => {
    if (activeTradeType === TradeType.BUY) {
      const shouldDisableTransaction =
        !isActiveWallet ||
        (!isUserHaveAccount &&
          phantomWalletSolanaBalance &&
          +phantomWalletSolanaBalance <= (+inputAmount || 0) + 0.0023) ||
        !phantomWalletSolanaBalance ||
        +phantomWalletSolanaBalance <= 0;
      setIsDisableTransaction(shouldDisableTransaction);
    } else if (activeTradeType === TradeType.SELL) {
      setIsDisableTransaction(false);
    }
  }, [isActiveWallet, phantomWalletSolanaBalance, currentCountTokensAndPrice, inputAmount, activeTradeType]);
  

  return (
    <div>
      <InputAmountField
        handleInputChange={handleInputChange}
        solanaAmount={inputAmount}
        activeTradeType={activeTradeType}
      />
      <div className="flex justify-start space-x-[6px] overflow-x-auto overflow-y-hidden  scrollbar-hide pt-2">
        {amountOptions.map((amount) => (
          <AmountOption
            key={amount}
            amount={amount}
            activeTradeType={activeTradeType}
            isActiveAmount={amount === selectedAmountOption}
            onClick={handleAmountClick}
          />
        ))}
      </div>
      <p className="pt-4 pb-3 text-center text-[12px] text-lavenderGray">{message}</p>
      <Button
        handleClick={handleTransaction}
        text={activeTradeType}
        style={isDisableTransaction ? "grey" : setBackgroundTradeType(activeTradeType)}
        attributes={{ disabled: isDisableTransaction }}
      />
      {isOpened && (
        <ConfirmPurchase
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          inputAmount={+inputAmount}
          activeTradeType={activeTradeType}
        />
      )}
    </div>
  );
});

export default TransactionControl;
